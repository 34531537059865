<template>
  <div class="border custom-border p-1">
    <ul
      class="
        b-form-tags-list
        list-unstyled
        mb-0
        d-flex
        flex-wrap
        align-items-center
      "
    >
      <li
        :title="item"
        v-for="(item, index) in listTag"
        :key="index"
        class="
          badge
          b-form-tag
          d-inline-flex
          align-items-baseline
          mw-100
          badge-secondary
          mb-25
        "
      >
        <span class="b-form-tag-content flex-grow-1 text-truncate">
          {{ item }}
        </span>
        <button @click.stop="deleteTag(index)" class="close b-form-tag-remove">
          x
        </button>
      </li>
      <li class="b-from-tags-field flex-grow-1">
        <b-input-group class="input-group-merge">
          <b-form-input v-model="tag" :placeholder="placeholder" />
          <b-input-group-prepend v-if="tag" is-text>
            <b-button @click="addTag" variant="primary" size="sm">
              Add
            </b-button>
          </b-input-group-prepend>
        </b-input-group>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    listTag: { type: Array, required: true },
    placeholder: { type: String, default: "" },
  },
  data() {
    return {
      tag: null,
    };
  },
  methods: {
    addTag() {
      this.listTag.push(this.tag.trim());
      this.tag = null;
    },
    deleteTag(index) {
      this.listTag.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.input-group-text {
  border: 0;
  padding: 0.2rem !important;
}
.form-control {
  border: 0;
}
.custom-border {
  border: 1 solid #d8d6de;
  border-radius: 5px;
}
.custom-badge {
  padding: 1px;
}
.b-form-tag-remove {
  font-size: 95% !important;
}
</style>
