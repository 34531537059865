<template>
  <div>
    <b-button variant="outline-primary" pill @click="create">
      <span class="align-middle">{{ title }}</span>
      <feather-icon icon="PlusIcon" class="ml-50" />
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "Create Sentence" },
  },
  computed: {
    userData() {
      return this.$store.getters["auth/userData"];
    },
  },
  methods: {
    create() {
      let new_sentence = {
        id: Date.now(),
        content: "",
        spelling: "",
        time_limit: 20,
        sample: {
          user: {
            avatar: {
              type: "image",
              value: null,
              meta: {
                url: null,
                alt: null,
              },
            },
            name: "",
            gender: null,
          },
          audio: {
            type: "audio",
            value: null,
            meta: {
              title: null,
            },
          },
        },
        subtitle: {
          type: "translatable-text",
          meta: {
            default_lang: "vi",
          },
          value: "",
          translations: [
            {
              language: "vi",
              value: "",
            },
          ],
        },
      };
      new_sentence[`subtitle_${this.userData.language}`] = "";

      this.$emit("created", new_sentence);
    },
  },
};
</script>
