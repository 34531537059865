import defaultConstant from "./_components/defaultConstant"
export default{
  title: "",
  type: defaultConstant.SINGLE_CHOICE,
  time: null,
  answers: [],
  questions: [],
  correct_answer_trans: {
    type: 'translatable-text',
    meta: {
      default_lang: 'vi'
    },
    value: '',
    translations: [
      {
        language: 'vi',
        value: ''
      }
    ]
  },
  answer_explain: {
    type: 'translatable-text',
    meta: {
      default_lang: 'vi'
    },
    value: '',
    translations: [
      {
        language: 'vi',
        value: ''
      }
    ]
  }
}