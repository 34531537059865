<template>
    <div>
      <h4 class="h4">Sentences</h4>
      <div class="mt-1">
        <app-collapse type="shadow" class="mt-2 border p-1">
          <draggable v-model="componentData" class="list-group list-group-flush" tag="ul"
            handle=".sentence-grab-button"
          >
            <transition-group type="transition" name="list">
              <app-collapse-item v-for="(sentence, sentenceIndex) in componentData" :key="'sentence' + sentenceIndex || sentence.id"
                class="question-item"
                :title="`Sentence ${sentenceIndex + 1}`" :is-visible="sentenceIndex === componentData.length - 1"
              >
                <template slot="header">
                  <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100">
                    <div class="d-flex align-items-center w-100 sentence-grab-button" style="cursor: grab;">
                      <feather-icon icon="TargetIcon" />
                      <span class="lead collapse-title ml-50 text-truncate d-block w-100" style="max-width: 90%">{{ sentence.content || `Sentence id: ${sentence.id}` }}</span>
                    </div>
                    <div style="position: absolute; right: 30px; ">
                      <b-button variant="flat-danger" class="btn-icon rounded-circle"
                        @click.stop="removeSentence(sentenceIndex)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </div>
                  </div>
                </template>
                <sentence-card :sentence-data="sentence" />
              </app-collapse-item>
            </transition-group>
          </draggable>

          <div v-if="!componentData.length" class="text-center"><span>No sentence, create them!</span></div>
        </app-collapse>
        <sentence-create-form class="text-center mt-1"
          @created="addSentence"
        />
      </div>
    </div>
</template>
<script>
import SentenceCreateForm from './SentenceCreateForm.vue'
import SentenceCard from './SentenceCard.vue'
import draggable from 'vuedraggable'
export default {
    components: {
      SentenceCreateForm,
      SentenceCard,
      draggable
    },
    props: {
      	componentData: { type: Array, required: true },
    },
    methods:{
        async addSentence(sentence) {
            this.componentData.push(sentence)
        },
        async removeSentence(sentenceIndex) {
            this.componentData.splice(sentenceIndex, 1)
        },
    }
}
</script>