<template>
  <b-card title="Content">
    <ui-component-list :componentList="componentData.context" class="mb-2" />
    <conversation-card v-if="componentData.conversations" :conversations="componentData.conversations" class="mb-1" />
    <questions-card v-if="componentData.questions" :content="componentData" />
    <question-card v-if="componentData.question" :content="componentData.question" />
  </b-card>
</template>

<script>
import ConversationCard from "./_components/ConversationCard.vue";
import QuestionsCard from "./_components/QuestionsCard.vue";
import QuestionCard from "./_components/QuestionCard.vue"
export default {
  name:"listening-field",
  components: {
    ConversationCard,
    QuestionsCard,
    QuestionCard
  },
  props: {
    componentData: { type: Object, required: true },
  },
  methods: {
    deleteContent(index) {
      this.componentData.splice(index, 1);
    },
  },
};
</script>
