<template>
  <app-collapse type="shadow" class="mt-1 border p-1">
    <div><h5>Question</h5></div>
    <draggable
      :list="content.questions"
      class="list-group list-group-flush"
      tag="ul"
      handle=".grab-button"
    >
      <transition-group type="transition" name="list">
        <app-collapse-item
          title=""
          v-for="(item, index) in content.questions"
          :key="item._id || index"
          class="drag-item my-1 border"
          :is-visible="index + 1 == content.questions.length ? true : false"
        >
          <template slot="header">
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                flex-grow-1
                w-100
              "
            >
              <div
                class="d-flex align-items-center w-100 grab-button"
                style="cursor: grab"
              >
                <feather-icon icon="TargetIcon" />
                <span
                  class="lead collapse-title ml-50 text-truncate d-block w-100"
                  style="max-width: 90%"
                >
                  Question {{ index + 1 }}: {{ item.title }}
                </span>
              </div>
              <div style="position: absolute; right: 30px">
                <b-button
                  variant="flat-primary"
                  @click="editQuestion(index)"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  @click="deleteQuestion(index)"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </div>
          </template>
        </app-collapse-item>
      </transition-group>
    </draggable>
    <div>
      <b-form-checkbox v-model="content.is_shuffle">
        <span class="lead collapse-title ml-50 text-truncate d-block w-100">
          Random Question
        </span>
      </b-form-checkbox>
    </div>
    <div class="text-center mt-4">
      <b-button variant="primary" @click="openModal" size="sm">
        Add Question
      </b-button>
    </div>

    <questions-type-modal
      :questions="content.questions"
      ref="questionsTypeModal"
      :is-open="isOpen"
    />
  </app-collapse>
</template>
<script>
import QuestionsTypeModal from "./QuestionsTypeModal.vue";
import draggable from "vuedraggable";

export default {
  components: {
    QuestionsTypeModal,
    draggable,
  },
  props: {
    content: { type: Object, required: true },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openModal() {
      this.$refs.questionsTypeModal.openModal();
    },
    editQuestion(indexQuestion) {
      this.$refs.questionsTypeModal.openModal(indexQuestion);
    },
    deleteQuestion(indexQuestion) {
      this.content.questions.splice(indexQuestion, 1);
    },
  },
};
</script>
