<template>
    <div class="page-content" v-if="item">
        <page-breadcrumb title="Exam Detail" class="mb-2" :items="breadcrumbItems" />
        <section class="mb-2">
            <b-card class="mt-1">
                <form-generator :model="item" :schema="item_form_schema" />
            </b-card>
            <h2>Guide Content</h2>
            <b-card>
                <ui-component :component-data="item.guide_content" />
            </b-card>
        </section>
        <div class="my-2">
            <h2>Parts</h2>
            <b-card>
                <app-collapse type="shadow" class="mt-2 border p-1">
                    <draggable v-model="item.parts" class="list-group list-group-flush" tag="ul"
                        handle=".sentence-grab-button"
                    >
                        <transition-group type="transition" name="list">
                            <app-collapse-item v-for="(part, part_index) in item.parts" :key="'part-' + part_index"
                                class="parts-item" :title="part.title"
                            >
                                <form-generator :model="part" :schema="part_form_schema" />
                                <h2>Guide Content</h2>
                                <b-card>
                                    <ui-component :component-data="part.guide_content" />
                                </b-card>
                                <level-component
                                    v-if="part.type != 3"
                                    :levels="part.levels"
                                    :part_index="part_index"
                                    :part_type="part.type"
                                />
                                <div v-else class="mt-2">
                                    <div>
                                        <h3>Interaction</h3>
                                        <level-component
                                            :levels="part.interaction.levels"
                                            :part_index="part_index"
                                            :part_type="3"
                                        />
                                    </div>
                                    <div class="mt-2">
                                        <h3>Pronunciation</h3>
                                        <level-component
                                            :levels="part.pronunciation.levels"
                                            :part_index="part_index"
                                            :part_type="4"
                                        />
                                    </div>
                                </div>
                            </app-collapse-item>
                        </transition-group>
                    </draggable>
                    <div class="d-flex justify-content-center mt-1">
                        <data-create-popup title="Add Part"
                            :schema="create_part_schema" :default-data="partDefault"
                            :create-data-fn="addPart" 
                            ref="createPartSchema"
                        />
                    </div>
                </app-collapse>
            </b-card>
        </div>
        <!-- <question-modal
            ref="questionModal"
            :is-open="isOpen"
            @saveQuestion="saveQuestion"
        /> -->
        <action-buttons
            update-button
            back-button
            delete-button
            @update="update"
            :is-updating="isUpdating"
            @delete="deleteExam"
            :is-deleting="isDeleting"
        />
    </div>
</template>
<script>
import service from '../service'
import { TYPE_OPTIONS, partDefault } from '../constants';
import PartComponent from './_components/PartComponent.vue'
import LevelComponent from './_components/LevelComponent.vue'
const item_form_schema = [
    {
        cols: 3,
        fields: [
            { label: 'Guide Image', field: "guide_image", input_type: "ui-component"},
        ]
    },
    {
        cols: 9,
        fields: [
            { label: 'Title', field: 'title', validate: { required: true } },
            { label: 'Type', field: 'type', input_type: 'select', options: TYPE_OPTIONS, disabled: true },
            { label: 'Slug', field: 'slug', validate: { required: true }},
        ]
    }
];
const part_form_schema = [
    {
        cols: 3,
        fields: [
            { label: 'Guide Image', field: "guide_image", input_type: "ui-component"},
        ]
    },
    {
        cols: 9,
        fields: [
            { label: 'Title', field: 'title', validate: { required: true } },
            { label: 'Type', field: 'type', input_type: 'select', options: [{text: "Vocab", value: 1},{text: "Listening", value: 2},{text: "Spoken - Interaction", value: 3}], disabled: true}
        ]
    }
];
const create_part_schema = [
    {
        cols: 12,
        fields: [
            { label: 'Title', field: 'title', validate: { required: true } },
            { label: 'Type', field: 'type', input_type: 'select', options: [{text: "Vocab", value: 1},{text: "Listening", value: 2},{text: "Spoken - Interaction", value: 3}]}
        ]
    }
]
import draggable from 'vuedraggable'
export default {
    components:{
        draggable,
        PartComponent,
        LevelComponent
    },
    data(){
        return{
            item_form_schema,
            item: null,
            part_form_schema,
            isOpen: false,
            isUpdating: false,
            isDeleting: false,
            partDefault,
            create_part_schema
        }
    },
    computed: {
        itemId() {
            return this.$route.params.id;
        },
        breadcrumbItems() {
            let items = [
                {
                    text: 'Exams',
                    to: { name: 'goga-exam-list' },
                },
                {
                    text: `${this.item ? this.item.title : this.itemId}`, active: true
                },
            ]
            return items
        },
        authLanguage() {
            return this.$store.getters["auth/language"];
        },
    },
    created() {
        this.getExam()
    },
    methods: {
        async getExam(){
            this.item = await service.get({id: this.itemId});
        },
        async update() {
            this.isUpdating = true;
            const response = await service.update({
                data: JSON.stringify(this.item),
            });
            if (response.data.statusCode === 200) {
                    this.$store.dispatch("pushSuccessNotify", {
                    text: "Successfully updated stage!",
                });
            }
            this.getExam();
            this.isUpdating = false;
        },
        async deleteExam(){

        },
        addLevel(part_index){
            this.item.parts[part_index].levels.push({
                question_number: 2,
                questions: []
            })
        },
		deleteLevel(part_index, level_index){
			this.item.parts[part_index].levels.splice(level_index, 1)
		},
        addQuestion(part_type, part_index, level_index){
            this.item.parts[part_index].levels[level_index].questions.push(JSON.parse(JSON.stringify(this.defaultQuestion[part_type])))
        },
        deleteQuestion(part_index, level_index, question_index){
            this.item.parts[part_index].levels[level_index].questions.splice(question_index, 1)
        },
        addPart(part){
            if (part.type == 3){
                part.interaction = {
                    levels: []
                };
                part.pronunciation = {
                    levels: []
                }
                delete part.levels
            }
            this.item.parts.push(part);
            this.$refs.createPartSchema.cancel();
        },
    }
}
</script>
<style scoped>
    .parts-level {
        background-color: #f4f2f7;
    }
</style>