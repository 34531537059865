<template>
  <div>
    <b-form-group>
      <label>
        <strong class="h6">Content text</strong>
      </label>
      <b-form-textarea
        rows="3"
        v-model="sentenceData.content"
      />

      <label>
        <strong class="h6 mt-1 d-block">Spelling text</strong>
      </label>
      <b-form-textarea
        rows="3"
        v-model="sentenceData.spelling"
      />

      <label>
        <strong class="h6 mt-1 d-block">Subtitle</strong>
      </label>
      <!-- <b-form-textarea
        rows="3"
        v-model="sentenceData[`subtitle_${userData.language}`]"
      /> -->
      <ui-component :component-data="sentenceData.subtitle" class="border" />
    </b-form-group>
    <strong class="h6">Speaking Duration</strong>
    <b-input-group append="seconds">
      <b-form-input type="number" v-model="sentenceData.time_limit" name="time_limit" />
    </b-input-group>
    <b-row class="mt-1">
      <b-col cols="6">
        <strong class="h6">Sample Audio</strong>
        <ui-component :component-data="sentenceData.sample.audio" class="border" />
      </b-col>
      <b-col cols="6">
        <strong class="h6">Sample User</strong>
        <v-select v-model="sentenceData.sample.user" :options="sample_users" label="name">
          <template slot="selected-option" slot-scope="option">
            <div class="flex items-center">
              <b-avatar :src="option.avatar.value" />
              <span class="ml-2">{{ option.name }}</span>
            </div>
          </template>
          <template slot="option" slot-scope="option">
            <div class="flex items-center">
              <b-avatar :src="option.avatar.value" />
              <span class="ml-2">{{ option.name }}</span>
            </div>
          </template>
        </v-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    sentenceData: { type: Object, required: true },
  },
  data() {
    return {
      sample_users: [
        {
          name: 'user 1',
          avatar: {
            type: 'image',
            value: 'https://v3-cms.x3english.com/storage/image/user/man-1.jpeg',
          },
          gender: true,
        },
        {
          name: 'user 2',
          avatar: {
            type: 'image',
            value: 'https://v3-cms.x3english.com/storage/image/user/woman-1.jpeg',
          },
          gender: false,
        },
        {
          name: 'user 3',
          avatar: {
            type: 'image',
            value: 'https://v3-cms.x3english.com/storage/image/user/man-2.jpeg',
          },
          gender: true,
        },
        {
          name: 'user 4',
          avatar: {
            type: 'image',
            value: 'https://v3-cms.x3english.com/storage/image/user/woman-2.jpeg',
          },
          gender: false,
        },
        {
          name: 'kid 1',
          avatar: {
            type: 'image',
            value: 'https://v3-cms.x3english.com/storage/image/user/kid-1.png',
          },
          gender: true,
        },
        {
          name: 'kid 2',
          avatar: {
            type: 'image',
            value: 'https://v3-cms.x3english.com/storage/image/user/kid-2.png',
          },
          gender: true,
        },
        {
          name: 'kid 3',
          avatar: {
            type: 'image',
            value: 'https://v3-cms.x3english.com/storage/image/user/kid-3.png',
          },
          gender: false,
        },
        {
          name: 'kid 4',
          avatar: {
            type: 'image',
            value: 'https://v3-cms.x3english.com/storage/image/user/kid-4.png',
          },
          gender: true,
        },
      ],
    }
  },
  computed: {
    userData() {
      return this.$store.getters['auth/userData']
    },
  },
}
</script>
