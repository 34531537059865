<template>
  <div>
    <b-modal
      id="modal-primary"
      ok-only
      ok-title="Save"
      modal-class="modal-primary"
      centered
      title="Select Question Type"
      v-model="isOpen"
      @ok="save"
      :ok-disabled="type_question ? false : true"
    >
      <div class="demo-inline-spacing">
        <b-form-radio
          v-model="type_question"
          name="type-question"
          :value="single_choice"
        >
          Single choice
        </b-form-radio>
        <b-form-radio
          v-model="type_question"
          name="type-question"
          :value="arrange_sentences"
        >
          Arrange sentences
        </b-form-radio>
      </div>
    </b-modal>
    <question-modal
      :type_question="type_question"
      ref="questionModal"
      @save_question="saveQuestion"
    />
  </div>
</template>

<script>
import QuestionModal from "./QuestionModal.vue";
import defaultConstant from "./defaultConstant";
export default {
  components: {
    QuestionModal,
  },
  props: {
    question: { type: Object, required: true },
  },
  data() {
    return {
      isOpen: false,
      type_question: defaultConstant.SINGLE_CHOICE,
      single_choice: defaultConstant.SINGLE_CHOICE,
      arrange_sentences: defaultConstant.ARRANGE_SENTENCES,
      indexQuestion: null,
    };
  },
  methods: {
    openModal() {
    //   if (indexQuestion || indexQuestion == 0) {
    //     this.indexQuestion = indexQuestion;
    //     this.type_question = this.questions[indexQuestion]?.type;
    //   } else {
    //     this.type_question = null;
    //     this.indexQuestion = null;
    //   }
      this.type_question = this.question.type;
      // console.log(this.type_question)
      this.isOpen = true;
    },
    save() {
      this.$refs.questionModal.openModal(this.question);
    },
    saveQuestion(question){
      this.question = question;
      console.log(question)
    }
  },
};
</script>
