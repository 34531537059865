<template>
  <app-collapse type="shadow" class="mt-1 border p-1">
    <div><h5>Question</h5></div>
        <app-collapse-item
          title=""
          class="drag-item my-1 border"
        >
          <template slot="header">
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                flex-grow-1
                w-100
              "
            >
              <div
                class="d-flex align-items-center w-100 grab-button"
                style="cursor: grab"
              >
                <feather-icon icon="TargetIcon" />
                <span
                  class="lead collapse-title ml-50 text-truncate d-block w-100"
                  style="max-width: 90%"
                >
                  {{ content.title }}
                </span>
              </div>
              <div style="position: absolute; right: 30px">
                <b-button
                  variant="flat-primary"
                  @click="editQuestion()"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </div>
            </div>
          </template>
        </app-collapse-item>

    <question-type-modal
      :question="content"
      ref="questionTypeModal"
      :is-open="isOpen"
    />
  </app-collapse>
</template>
<script>
import QuestionTypeModal from "./QuestionTypeModal.vue";
import draggable from "vuedraggable";

export default {
  components: {
    QuestionTypeModal,
    draggable,
  },
  props: {
    content: { type: Object, required: true },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openModal() {
      this.$refs.questionTypeModal.openModal();
    },
    editQuestion() {
      this.$refs.questionTypeModal.openModal();
    },
  },
};
</script>
