<template>
  <div>
    <b-card class="mb-0">
      <b-row>
        <b-col md="5">
          <b-avatar
            v-if="componentData.value"
            :src="componentData.value"
            size="70px"
          />

          <b-img
            v-else
            v-bind="{
              blank: true,
              blankColor: '#777',
              width: 75,
              height: 75,
              class: 'm1',
            }"
            rounded="circle"
            alt="Circle image"
            class="d-inline-block mr-1 mb-1"
          />
        </b-col>
        <b-col md="7">
          <div class="d-flex pt-1">
            <b-button
              size="sm"
              variant="gradient-primary"
              pill
              @click="openUpdateForm"
            >
              <span class="align-middle">Upload</span>
              <feather-icon icon="UploadIcon" class="ml-50" />
            </b-button>
            <b-button
              v-if="!!componentData.value"
              size="sm"
              variant="gradient-danger"
              pill
              class="ml-50"
              @click="clearImage"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
              <span class="align-middle">Clear</span>
            </b-button>
          </div>
          <div v-if="componentData.meta.redirect_link" class="mt-1">
            <strong>Redirect Link:</strong>
            <a :href="componentData.meta.redirect_link">{{
              componentData.meta.redirect_link
            }}</a>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      v-model="isModalShow"
      v-if="editingData"
      title="Update Data"
      hide-footer
      size="lg"
      modal-class="modal-primary"
    >
      <b-form-group label="Image URL" label-for="image_src">
        <b-form-input
          v-model="editingData.value"
          id="image_src"
          name="image_src"
          placeholder="URL"
        />
      </b-form-group>

      <b-form-group label="Image Alt" label-for="image_alt">
        <b-form-input
          v-model="editingData.meta.alt"
          id="image_alt"
          name="image_alt"
        />
      </b-form-group>

      <b-form-group label="Redirect Link" label-for="image_link">
        <b-form-input
          v-model="editingData.meta.redirect_link"
          id="image_link"
        />
      </b-form-group>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button variant="primary" @click="update">Update</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { resetObject } from "@core/utils";
import defaultObject from "@core/layouts/components/input-fields/image-field/defaultObject";

export default {
  name: "ImageField",
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
      isModalShow: false,
      editingData: null,
    };
  },
  created() {},
  methods: {
    clearImage() {
      resetObject(this.componentData, { ...defaultObject });
      this.$emit("updated");
    },
    cancel() {
      this.isModalShow = false;
    },
    update() {
      resetObject(this.componentData, this.editingData);
      this.isModalShow = false;
      this.$emit("updated");
    },
    openUpdateForm() {
      this.editingData = { ...this.componentData };
      this.isModalShow = true;
    },
  },
};
</script>
